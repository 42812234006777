@import url('https://fonts.googleapis.com/css2?family=Pretendard:wght@400;600;700&display=swap');

/* 공통 스타일 */
.main {
  font-family: 'Pretendard', sans-serif;
  color: #333;
  line-height: 1.6;
}

.section {
  margin: 20px;
  padding: 15px;
  border-radius: 10px;
  background-color: #f8f9fa;
}

/* 버튼 및 링크 스타일 */
.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
}

.btn:hover, .btn:focus {
  background-color: #0056b3;
  color: white;
  text-decoration: none;
}

/* 기존 .learn-more-btn, .contact-btn 스타일 제거 */

/* 히어로 섹션 */
.hero {
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.hero-content {
  text-align: center;
  padding-top: 80px;
}

.hero-content h1, .hero-content .subtitle {
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.hero-content h1 {
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero-content .highlight {
  color: #FF8C00;
}

.hero-content .subtitle {
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 1.2;
}

/* 콘텐츠 블록 */
.content-block {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin: 50px auto;
  max-width: 1000px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.content-block img {
  width: 50%;
  height: 500px;
  object-fit: cover;
}

.text-content {
  padding: 40px;
  width: 50%;
}

.text-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.text-content p {
  font-size: 1rem;
  line-height: 1.6;
}

.content-block.reverse {
  flex-direction: row-reverse;
}

/* 비전 블록 */
.vision-block {
  flex-direction: row;
}

.vision-block .text-content {
  order: 1;
}

.vision-block img {
  order: 2;
}

/* 제품 블록 */
.product-block {
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.product-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  flex: 1;
  min-width: 250px;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card h3 {
  font-size: 1.3em;
  color: #FF8C00;
  margin-bottom: 10px;
}

.product-card p {
  font-size: 1em;
  color: #333;
  margin-bottom: 15px;
}

.product-card .btn {
  margin-top: 10px;
}

/* 연락처 섹션 */
.contact-us {
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
}

.contact-us img {
  width: 50%;
  height: 400px;
  object-fit: cover;
}

.contact-us .text-content {
  width: 50%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-us h2 {
  margin-bottom: 20px;
}

.contact-us p {
  margin-bottom: 30px;
}

.contact-us .btn {
  align-self: flex-start;
}

/* Our Team 섹션 */
.our-team {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Our Vision 섹션 */
.our-vision {
  text-align: center;
}

/* Our Product 섹션 */
.our-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .section {
    margin: 10px;
  }

  .content-block, .vision-block, .contact-us {
    flex-direction: column;
    height: auto;
  }

  .content-block img, .vision-block img, .contact-us img,
  .content-block .text-content, .vision-block .text-content, .contact-us .text-content {
    width: 100%;
    height: auto;
  }

  .text-content {
    padding: 30px;
  }

  /* Vision 블록 모바일 순서 변경 */
  .vision-block img {
    order: 1;
  }

  .vision-block .text-content {
    order: 2;
  }

  /* Our Team과 Our Vision 섹션 모바일 스타일 */
  .content-block.our-team .text-content,
  .content-block.our-vision .text-content {
    padding: 30px;
    height: 150%; /* 기존 높이의 1.5배 */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* 텍스트 내용이 넘치지 않도록 스크롤 추가 */
  .content-block.our-team .text-content,
  .content-block.our-vision .text-content {
    overflow-y: auto;
  }

  /* 좌우 여백 30px 적용 */
  .content-block.our-team,
  .content-block.our-vision {
    padding-left: 30px;
    padding-right: 30px;
  }

  .btn {
    width: 150px;
    margin-bottom: 10px;
  }

  .contact-us {
    flex-direction: column;
  }

  .contact-us img,
  .contact-us .text-content {
    width: 100%;
  }

  .contact-us img {
    height: 300px;
  }

  .contact-us .text-content {
    padding: 20px;
  }

  .contact-us h2,
  .contact-us p,
  .contact-us .btn {
    margin-left: 20px;
  }
}