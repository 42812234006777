/* 전체 앱에 대한 기본 스타일 */
body {
    font-family: 'Noto Sans KR', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* 공통 컨테이너 스타일 */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* 섹션 스타일 */
  .section {
    padding: 60px 0;
  }
  
  /* 제목 스타일 */
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }
  
  /* 버튼 스타일 */
  .button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  /* 반응형 디자인 */
  @media (max-width: 768px) {
    .section {
      padding: 40px 0;
    }
  }
  
  /* 페이지 전환 애니메이션 */
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
  
  /* 유틸리티 클래스 */
  .text-center {
    text-align: center;
  }
  
  .mt-1 { margin-top: 0.25rem; }
  .mt-2 { margin-top: 0.5rem; }
  .mt-3 { margin-top: 1rem; }
  .mt-4 { margin-top: 1.5rem; }
  .mt-5 { margin-top: 3rem; }
  
  .mb-1 { margin-bottom: 0.25rem; }
  .mb-2 { margin-bottom: 0.5rem; }
  .mb-3 { margin-bottom: 1rem; }
  .mb-4 { margin-bottom: 1.5rem; }
  .mb-5 { margin-bottom: 3rem; }
  
  /* 추가적인 커스텀 스타일 */
  .bg-light {
    background-color: #f8f9fa;
  }
  
  .bg-dark {
    background-color: #343a40;
    color: white;
  }
  
  .text-primary {
    color: #007bff;
  }
  
  .text-secondary {
    color: #6c757d;
  }
  
  /* 그리드 시스템 (필요한 경우) */
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  /* 추가적인 반응형 스타일 */
  @media (max-width: 576px) {
    .container {
      padding: 0 15px;
    }
  }