.footer {
    background-color: #333;
    color: white;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-content {
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    justify-content: center; /* 가로 중앙 정렬 */
    padding: 20px 0; /* 상하 여백 추가 */
  }
  
  .footer-logo {
    height: 50px; /* 로고 높이를 50px로 유지 */
    width: auto; /* 비율 유지 */
    margin-right: 20px; /* 로고와 텍스트 사이 간격 */
  }
  
  .footer-text {
    display: flex;
    align-items: center; /* 텍스트 세로 중앙 정렬 */
    white-space: nowrap; /* 텍스트가 한 줄로 유지되도록 함 */
  }
  
  .footer-text p {
    margin: 0;
    display: flex;
    flex-direction: column; /* 저작권 정보와 주소를 세로로 배치 */
  }
  
  .copyright, .address {
    margin: 2px 0; /* 저작권 정보와 주소 사이 간격 */
  }
  
  @media (max-width: 768px) {
    .footer {
      height: auto;
      padding: 20px 0;
    }
  
    .footer-content {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  
    .footer-logo {
      height: 60px;
      margin-right: 0;
      margin-bottom: 15px;
    }
  
    .footer-text {
      text-align: center;
    }
  }

.footer {
    font-family: 'Pretendard', sans-serif;
  }