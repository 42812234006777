.avatar-details-page {
  font-family: 'Pretendard', sans-serif;
  color: #333;
  line-height: 1.6;
}

.avatar-details-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.main-title {
  font-size: 32px;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

.sub-title {
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-bottom: 40px;
}

.avatar-section {
  margin-bottom: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
}

.section-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.section-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.text-box, .model-box, .image-box {
  width: 48%;
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;
  width: 60%;
}

.text-content {
  text-align: center;
}

.model-box, .image-box {
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.animation-buttons {
  margin: 15px 0;
}

.animation-buttons button {
  margin: 0 5px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.animation-buttons button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .section-content {
    flex-direction: column;
  }
  
  .text-box, .model-box, .image-box {
    width: 100%;
    margin-bottom: 20px;
  }
}