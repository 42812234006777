.contact-form {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form input,
  .contact-form textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }

  .contact-container {
    display: flex;
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
  }
  
  .contact-info {
    flex: 1;
    padding: 40px;
    background-color: #f8f9fa;
    border-radius: 10px;
    margin-right: 40px;
  }
  
  .contact-info h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .sub-text {
    font-size: 1.1em;
    line-height: 1.6;
    color: #666;
  }
  
  .contact-form {
    flex: 2;
    max-width: 600px;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form input,
  .contact-form textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contact-form textarea {
    height: 150px; /* 기본 높이를 150px로 설정 */
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
    }
  
    .contact-info {
      margin-right: 0;
      margin-bottom: 40px;
    }
  
    .contact-form {
      max-width: 100%;
      padding: 15px;
      margin-bottom: 15px;
    }
  
    .contact-info,
    .contact-form {
      width: 100%;
      max-width: 100%;
    }
  
    .contact-form form {
      display: flex;
      flex-direction: column;
    }
  
    .contact-form form input,
    .contact-form form textarea {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .contact-form form button {
      width: 100%;
    }
  }