.product-page {
  font-family: 'Pretendard', sans-serif;
  color: #333;
  line-height: 1.6;
}

.product-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.product-intro {
  font-size: 2.5em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  color: #1a1a1a;
  line-height: 1.3;
}

.product-intro .highlight {
  color: #FF8C00;
}

.product-separator {
  width: 50px;
  height: 2px;
  background-color: #FF4500;
  margin: 20px auto;
}

.product-description {
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 40px;
  color: #666;
  line-height: 1.5;
}

.product-section {
  display: flex;
  margin-bottom: 40px;
  background-color: #f5f5f5;
  border-radius: 20px; /* 외부 박스의 라운드 처리 */
  overflow: hidden;
  padding: 20px; /* 내부 패딩 추가 */
}

.product-image {
  flex: 0 0 250px; /* 가로 폭을 250px로 고정 */
  height: calc(100% - 40px); /* 외부 블록 높이에서 상하 패딩(20px * 2)을 뺀 높이 */
  border-radius: 15px; /* 이미지 컨테이너의 라운드 처리 */
  overflow: hidden;
  margin-right: 20px; /* 오른쪽 마진 추가 */
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* 이미지 비율 유지 */
}

.product-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 세로 중앙 정렬 */
  padding: 20px;
}

.product-content h3 {
  font-size: 1.5em;
  font-weight: 600;
  color: #0066cc;
  margin: 0 0 10px 0;
  line-height: 1.3;
}

.product-content p {
  font-size: 0.9em;
  font-weight: 400;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.8;
}

.product-link {
  width: 200px;
  padding: 10px 0;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  font-size: 1em;
  margin-top: 20px;
}

.product-link.active {
  background-color: #0066cc;
  color: #fff;
  box-shadow: none; /* 그림자 효과 제거 (있었다면) */
}

.product-link.active:hover {
  background-color: #0052a3;
}

.product-link.inactive {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.product-highlight-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.product-highlight-box {
  flex: 0 0 48%;
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.product-highlight-box img {
  width: 70%; /* 이미지 크기를 30% 줄임 */
  height: auto; /* 높이를 자동으로 조정하여 비율 유지 */
  object-fit: contain; /* 이미지가 온전히 표시되도록 함 */
  margin-bottom: 15px; /* 이미지와 텍스트 사이 간격 추가 */
}

.product-highlight-box h3 {
  text-align: center;
  font-size: 1.2em;
  color: #333;
  margin: 0;
}

.product-full-width-section {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 40px;
}

.product-full-width-section h3 {
  text-align: center;
  font-size: 1.5em;
  color: #0066cc;
  margin-bottom: 20px;
}

.product-full-width-section .product-section {
  display: flex;
  align-items: center;
}

.product-full-width-section .product-image {
  flex: 0 0 50%; /* 이미지 영역을 50%로 설정 */
  height: auto; /* 높이를 자동으로 조정 */
  margin-right: 20px;
}

.product-full-width-section .product-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.product-full-width-section .product-content {
  flex: 0 0 50%; /* 텍스트 영역을 50%로 설정 */
}

@media (max-width: 768px) {
  .product-section {
    flex-direction: column;
    padding: 15px; /* 모바일에서 패딩 줄임 */
  }

  .product-image {
    flex: 0 0 200px; /* 모바일에서 높이 고정 */
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px; /* 아래쪽 마진 추가 */
  }

  .product-content {
    align-items: center; /* 텍스트와 버튼을 가로 중앙 정렬 */
    text-align: center; /* 텍스트를 가운데 정렬 */
  }

  .product-content h3 {
    text-align: center; /* 제목을 가운데 정렬 */
  }

  .product-content p {
    text-align: center; /* 본문을 가운데 정렬 */
  }

  .product-link {
    margin-left: auto; /* 버튼을 가운데 정렬 */
    margin-right: auto;
  }

  .product-highlight-section {
    flex-direction: column;
  }

  .product-highlight-box {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  .product-full-width-section .product-section {
    flex-direction: column;
  }

  .product-full-width-section .product-image,
  .product-full-width-section .product-content {
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}